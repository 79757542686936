<div class="container-fluid">
  <p class="msg" [innerHTML]='info'></p>
  <div tappable (click)="btrmore()">
    <p *ngIf="showBN"><span class='recfy' [innerHTML]='recfyBT'></span><span class="badge badge-pill badge-primary">Know more..</span></p>
  </div>
  <div class="row"><div id="bchart" class="col col-sm-6"> <div #birthChart></div></div></div>
  <div><span class="lbl">AYANAMSA: </span><span class="more">{{aynt}}</span><span class="badge badge-pill badge-primary" tappable (click)="chgayan()">change</span></div>
  <div *ngIf="showSGF">
    <div class="row lines title"><div class="col col-sm-2">PLANET</div><div class="col col-sm-2">POS</div><div class="col col-sm-2">SIGN</div><div class="col col-sm-2">STAR</div><div class="col col-sm-2">SUB</div><div class="col col-sm-2">SUBSUB</div></div>
    <div class="row lines subtitle"><div class="col col-sm-2"><div [innerHTML]='pnam1'></div></div><div class="col col-sm-2"><div [innerHTML]='ppos1'></div></div><div class="col col-sm-2"><div [innerHTML]='pras1'></div></div><div class="col col-sm-2"><div [innerHTML]='pnak1'></div></div><div class="col col-sm-2"><div [innerHTML]='nakl1'></div></div><div class="col col-sm-2"><div [innerHTML]='sub1'></div></div></div>
    <div class="row lines subtitle"><div class="col col-sm-2"><div [innerHTML]='pnam2'></div></div><div class="col col-sm-2"><div [innerHTML]='ppos2'></div></div><div class="col col-sm-2"><div [innerHTML]='pras2'></div></div><div class="col col-sm-2"><div [innerHTML]='pnak2'></div></div><div class="col col-sm-2"><div [innerHTML]='nakl2'></div></div><div class="col col-sm-2"><div [innerHTML]='sub2'></div></div></div>
    <div class="row lines subtitle"><div class="col col-sm-2"><div [innerHTML]='pnam3'></div></div><div class="col col-sm-2"><div [innerHTML]='ppos3'></div></div><div class="col col-sm-2"><div [innerHTML]='pras3'></div></div><div class="col col-sm-2"><div [innerHTML]='pnak3'></div></div><div class="col col-sm-2"><div [innerHTML]='nakl3'></div></div><div class="col col-sm-2"><div [innerHTML]='sub3'></div></div></div>
    <div class="row lines subtitle"><div class="col col-sm-2"><div [innerHTML]='pnam4'></div></div><div class="col col-sm-2"><div [innerHTML]='ppos4'></div></div><div class="col col-sm-2"><div [innerHTML]='pras4'></div></div><div class="col col-sm-2"><div [innerHTML]='pnak4'></div></div><div class="col col-sm-2"><div [innerHTML]='nakl4'></div></div><div class="col col-sm-2"><div [innerHTML]='sub4'></div></div></div>
    <div class="row lines subtitle"><div class="col col-sm-2"><div [innerHTML]='pnam5'></div></div><div class="col col-sm-2"><div [innerHTML]='ppos5'></div></div><div class="col col-sm-2"><div [innerHTML]='pras5'></div></div><div class="col col-sm-2"><div [innerHTML]='pnak5'></div></div><div class="col col-sm-2"><div [innerHTML]='nakl5'></div></div><div class="col col-sm-2"><div [innerHTML]='sub5'></div></div></div>
    <div class="row lines subtitle"><div class="col col-sm-2"><div [innerHTML]='pnam6'></div></div><div class="col col-sm-2"><div [innerHTML]='ppos6'></div></div><div class="col col-sm-2"><div [innerHTML]='pras6'></div></div><div class="col col-sm-2"><div [innerHTML]='pnak6'></div></div><div class="col col-sm-2"><div [innerHTML]='nakl6'></div></div><div class="col col-sm-2"><div [innerHTML]='sub6'></div></div></div>
    <div class="row lines subtitle"><div class="col col-sm-2"><div [innerHTML]='pnam7'></div></div><div class="col col-sm-2"><div [innerHTML]='ppos7'></div></div><div class="col col-sm-2"><div [innerHTML]='pras7'></div></div><div class="col col-sm-2"><div [innerHTML]='pnak7'></div></div><div class="col col-sm-2"><div [innerHTML]='nakl7'></div></div><div class="col col-sm-2"><div [innerHTML]='sub7'></div></div></div>
    <div class="row lines subtitle"><div class="col col-sm-2"><div [innerHTML]='pnam8'></div></div><div class="col col-sm-2"><div [innerHTML]='ppos8'></div></div><div class="col col-sm-2"><div [innerHTML]='pras8'></div></div><div class="col col-sm-2"><div [innerHTML]='pnak8'></div></div><div class="col col-sm-2"><div [innerHTML]='nakl8'></div></div><div class="col col-sm-2"><div [innerHTML]='sub8'></div></div></div>
    <div class="row lines subtitle"><div class="col col-sm-2"><div [innerHTML]='pnam9'></div></div><div class="col col-sm-2"><div [innerHTML]='ppos9'></div></div><div class="col col-sm-2"><div [innerHTML]='pras9'></div></div><div class="col col-sm-2"><div [innerHTML]='pnak9'></div></div><div class="col col-sm-2"><div [innerHTML]='nakl9'></div></div><div class="col col-sm-2"><div [innerHTML]='sub9'></div></div></div>
    <div class="row lines title"><div class="col col-sm-2">CUSP</div><div class="col col-sm-2">POS</div><div class="col col-sm-2">SIGN</div><div class="col col-sm-2">STAR</div><div class="col col-sm-2">SUB</div><div class="col col-sm-2">SUBSUB</div></div>
    <div class="row lines subtitle"><div class="col col-sm-2"><div [innerHTML]='hnam1'></div></div><div class="col col-sm-2"><div [innerHTML]='hpos1'></div></div><div class="col col-sm-2"><div [innerHTML]='hras1'></div></div><div class="col col-sm-2"><div [innerHTML]='hnak1'></div></div><div class="col col-sm-2"><div [innerHTML]='hsub1'></div></div><div class="col col-sm-2"><div [innerHTML]='hssub1'></div></div></div>
    <div class="row lines subtitle"><div class="col col-sm-2"><div [innerHTML]='hnam2'></div></div><div class="col col-sm-2"><div [innerHTML]='hpos2'></div></div><div class="col col-sm-2"><div [innerHTML]='hras2'></div></div><div class="col col-sm-2"><div [innerHTML]='hnak2'></div></div><div class="col col-sm-2"><div [innerHTML]='hsub2'></div></div><div class="col col-sm-2"><div [innerHTML]='hssub2'></div></div></div>
    <div class="row lines subtitle"><div class="col col-sm-2"><div [innerHTML]='hnam3'></div></div><div class="col col-sm-2"><div [innerHTML]='hpos3'></div></div><div class="col col-sm-2"><div [innerHTML]='hras3'></div></div><div class="col col-sm-2"><div [innerHTML]='hnak3'></div></div><div class="col col-sm-2"><div [innerHTML]='hsub3'></div></div><div class="col col-sm-2"><div [innerHTML]='hssub3'></div></div></div>
    <div class="row lines subtitle"><div class="col col-sm-2"><div [innerHTML]='hnam4'></div></div><div class="col col-sm-2"><div [innerHTML]='hpos4'></div></div><div class="col col-sm-2"><div [innerHTML]='hras4'></div></div><div class="col col-sm-2"><div [innerHTML]='hnak4'></div></div><div class="col col-sm-2"><div [innerHTML]='hsub4'></div></div><div class="col col-sm-2"><div [innerHTML]='hssub4'></div></div></div>
    <div class="row lines subtitle"><div class="col col-sm-2"><div [innerHTML]='hnam5'></div></div><div class="col col-sm-2"><div [innerHTML]='hpos5'></div></div><div class="col col-sm-2"><div [innerHTML]='hras5'></div></div><div class="col col-sm-2"><div [innerHTML]='hnak5'></div></div><div class="col col-sm-2"><div [innerHTML]='hsub5'></div></div><div class="col col-sm-2"><div [innerHTML]='hssub5'></div></div></div>
    <div class="row lines subtitle"><div class="col col-sm-2"><div [innerHTML]='hnam6'></div></div><div class="col col-sm-2"><div [innerHTML]='hpos6'></div></div><div class="col col-sm-2"><div [innerHTML]='hras6'></div></div><div class="col col-sm-2"><div [innerHTML]='hnak6'></div></div><div class="col col-sm-2"><div [innerHTML]='hsub6'></div></div><div class="col col-sm-2"><div [innerHTML]='hssub6'></div></div></div>
    <div class="row lines subtitle"><div class="col col-sm-2"><div [innerHTML]='hnam7'></div></div><div class="col col-sm-2"><div [innerHTML]='hpos7'></div></div><div class="col col-sm-2"><div [innerHTML]='hras7'></div></div><div class="col col-sm-2"><div [innerHTML]='hnak7'></div></div><div class="col col-sm-2"><div [innerHTML]='hsub7'></div></div><div class="col col-sm-2"><div [innerHTML]='hssub7'></div></div></div>
    <div class="row lines subtitle"><div class="col col-sm-2"><div [innerHTML]='hnam8'></div></div><div class="col col-sm-2"><div [innerHTML]='hpos8'></div></div><div class="col col-sm-2"><div [innerHTML]='hras8'></div></div><div class="col col-sm-2"><div [innerHTML]='hnak8'></div></div><div class="col col-sm-2"><div [innerHTML]='hsub8'></div></div><div class="col col-sm-2"><div [innerHTML]='hssub8'></div></div></div>
    <div class="row lines subtitle"><div class="col col-sm-2"><div [innerHTML]='hnam9'></div></div><div class="col col-sm-2"><div [innerHTML]='hpos9'></div></div><div class="col col-sm-2"><div [innerHTML]='hras9'></div></div><div class="col col-sm-2"><div [innerHTML]='hnak9'></div></div><div class="col col-sm-2"><div [innerHTML]='hsub9'></div></div><div class="col col-sm-2"><div [innerHTML]='hssub9'></div></div></div>
    <div class="row lines subtitle"><div class="col col-sm-2"><div [innerHTML]='hnam10'></div></div><div class="col col-sm-2"><div [innerHTML]='hpos10'></div></div><div class="col col-sm-2"><div [innerHTML]='hras10'></div></div><div class="col col-sm-2"><div [innerHTML]='hnak10'></div></div><div class="col col-sm-2"><div [innerHTML]='hsub10'></div></div><div class="col col-sm-2"><div [innerHTML]='hssub10'></div></div></div>
    <div class="row lines subtitle"><div class="col col-sm-2"><div [innerHTML]='hnam11'></div></div><div class="col col-sm-2"><div [innerHTML]='hpos11'></div></div><div class="col col-sm-2"><div [innerHTML]='hras11'></div></div><div class="col col-sm-2"><div [innerHTML]='hnak11'></div></div><div class="col col-sm-2"><div [innerHTML]='hsub11'></div></div><div class="col col-sm-2"><div [innerHTML]='hssub11'></div></div></div>
    <div class="row lines subtitle"><div class="col col-sm-2"><div [innerHTML]='hnam12'></div></div><div class="col col-sm-2"><div [innerHTML]='hpos12'></div></div><div class="col col-sm-2"><div [innerHTML]='hras12'></div></div><div class="col col-sm-2"><div [innerHTML]='hnak12'></div></div><div class="col col-sm-2"><div [innerHTML]='hsub12'></div></div><div class="col col-sm-2"><div [innerHTML]='hssub12'></div></div></div>
    <div class="row lines subtitle"><div class="col com-sm-2 hn"><div tappable (click)="showInf('Fortuna')">Fortuna</div></div><div class="col col-sm-2"><div [innerHTML]='fpos'></div></div><div class="col col-sm-2"><div [innerHTML]='fras'></div></div><div class="col col-sm-2"><div [innerHTML]='fnak'></div></div><div class="col col-sm-2"><div [innerHTML]='fsub'></div></div><div class="col col-sm-2"><div [innerHTML]='fhssub'></div></div></div>
    <div class="row lines subtitle"><div class="col col-sm-2"><div>Ascendant</div></div><div class="col col-sm-2"><div [innerHTML]='apos'></div></div><div class="col col-sm-2"><div [innerHTML]='aras'></div></div><div class="col col-sm-2"><div [innerHTML]='anak'></div></div><div class="col col-sm-2"><div [innerHTML]='asub'></div></div><div class="col col-sm-2"><div></div></div></div>
    <div class="row lines title"><div class="col col-sm-4">PLANET</div><div class="col col-sm-8">SIGNIFICATORS</div></div>
    <div class="row lines subtitle"><div class="col col-sm-4"><div [innerHTML]='pn1'></div></div><div class="col col-sm-8"><div><span style='color:#FF0000;font-weight:bold'>{{ L1ps1 }}</span><span style='color:#3db161;font-weight:bold'>{{ L2ps1 }}</span><span style='color:#001EFF;font-weight:bold;'>{{L3ps1}}</span><span style='color:#f0882f;font-weight:'>{{L4ps1}}</span></div></div></div>
    <div class="row lines subtitle"><div class="col col-sm-12"><div [innerHTML]='krk1'></div></div></div>
    <div class="row lines subtitle"><div class="col col-sm-4"><div [innerHTML]='pn2'></div></div><div class="col col-sm-8"><div><span style='color:#FF0000;font-weight:bold'>{{ L1ps2 }}</span><span style='color:#3db161;font-weight:bold'>{{ L2ps2 }}</span><span style='color:#001EFF;font-weight:bold;'>{{L3ps2}}</span><span style='color:#f0882f;font-weight:'>{{L4ps2}}</span></div></div></div>
    <div class="row lines subtitle"><div class="col col-sm-12"><div [innerHTML]='krk2'></div></div></div>
    <div class="row lines subtitle"><div class="col col-sm-4"><div [innerHTML]='pn3'></div></div><div class="col col-sm-8"><div><span style='color:#FF0000;font-weight:bold'>{{ L1ps3 }}</span><span style='color:#3db161;font-weight:bold'>{{ L2ps3 }}</span><span style='color:#001EFF;font-weight:bold;'>{{L3ps3}}</span><span style='color:#f0882f;font-weight:'>{{L4ps3}}</span></div></div></div>
    <div class="row lines subtitle"><div class="col col-sm-12"><div [innerHTML]='krk3'></div></div></div>
    <div class="row lines subtitle"><div class="col col-sm-4"><div [innerHTML]='pn4'></div></div><div class="col col-sm-8"><div><span style='color:#FF0000;font-weight:bold'>{{ L1ps4 }}</span><span style='color:#3db161;font-weight:bold'>{{ L2ps4 }}</span><span style='color:#001EFF;font-weight:bold;'>{{L3ps4}}</span><span style='color:#f0882f;font-weight:'>{{L4ps4}}</span></div></div></div>
    <div class="row lines subtitle"><div class="col col-sm-12"><div [innerHTML]='krk4'></div></div></div>
    <div class="row lines subtitle"><div class="col col-sm-4"><div [innerHTML]='pn5'></div></div><div class="col col-sm-8"><div><span style='color:#FF0000;font-weight:bold'>{{ L1ps5 }}</span><span style='color:#3db161;font-weight:bold'>{{ L2ps5 }}</span><span style='color:#001EFF;font-weight:bold;'>{{L3ps5}}</span><span style='color:#f0882f;font-weight:'>{{L4ps5}}</span></div></div></div>
    <div class="row lines subtitle"><div class="col col-sm-12"><div [innerHTML]='krk5'></div></div></div>
    <div class="row lines subtitle"><div class="col col-sm-4"><div [innerHTML]='pn6'></div></div><div class="col col-sm-8"><div><span style='color:#FF0000;font-weight:bold'>{{ L1ps6 }}</span><span style='color:#3db161;font-weight:bold'>{{ L2ps6 }}</span><span style='color:#001EFF;font-weight:bold;'>{{L3ps6}}</span><span style='color:#f0882f;font-weight:'>{{L4ps6}}</span></div></div></div>
    <div class="row lines subtitle"><div class="col col-sm-12"><div [innerHTML]='krk6'></div></div></div>
    <div class="row lines subtitle"><div class="col col-sm-4"><div [innerHTML]='pn7'></div></div><div class="col col-sm-8"><div><span style='color:#FF0000;font-weight:bold'>{{ L1ps7 }}</span><span style='color:#3db161;font-weight:bold'>{{ L2ps7 }}</span><span style='color:#001EFF;font-weight:bold;'>{{L3ps7}}</span><span style='color:#f0882f;font-weight:'>{{L4ps7}}</span></div></div></div>
    <div class="row lines subtitle"><div class="col col-sm-12"><div [innerHTML]='krk7'></div></div></div>
    <div class="row lines subtitle"><div class="col col-sm-4"><div [innerHTML]='pn8'></div></div><div class="col col-sm-8"><div><span style='color:#FF0000;font-weight:bold'>{{ L1ps8 }}</span><span style='color:#3db161;font-weight:bold'>{{ L2ps8 }}</span><span style='color:#001EFF;font-weight:bold;'>{{L3ps8}}</span><span style='color:#f0882f;font-weight:'>{{L4ps8}}</span></div></div></div>
    <div class="row lines subtitle"><div class="col col-sm-12"><div [innerHTML]='krk8'></div></div></div>
    <div class="row lines subtitle"><div class="col col-sm-4"><div [innerHTML]='pn9'></div></div><div class="col col-sm-8"><div><span style='color:#FF0000;font-weight:bold'>{{ L1ps9 }}</span><span style='color:#3db161;font-weight:bold'>{{ L2ps9 }}</span><span style='color:#001EFF;font-weight:bold;'>{{L3ps9}}</span><span style='color:#f0882f;font-weight:'>{{L4ps9}}</span></div></div></div>
    <div class="row lines subtitle"><div class="col col-sm-12"><div [innerHTML]='krk9'></div></div></div>
    <div class="row lines title"><div class="col col-sm-4">HOUSE</div><div class="col col-sm-8">SIGNIFICATORS</div></div>
    <div class="row lines subtitle"><div class="col col-sm-4 hn"><div [innerHTML]='hn1' tappable (click)="hsignf(1)"></div></div><div class="col col-sm-8"><div><span style='color:#FF0000;font-weight:bold'>{{ L1hs1 }}</span><span style='color:#3db161;font-weight:bold'>{{ L2hs1 }}</span><span style='color:#001EFF;font-weight:bold;'>{{L3hs1}}</span><span style='color:#f0882f;font-weight:'>{{L4hs1}}</span></div></div></div>
    <div class="row lines subtitle"><div class="col col-sm-4 hn"><div tappable (click)="hsignf(2)" [innerHTML]='hn2'></div></div><div class="col col-sm-8"><div><span style='color:#FF0000;font-weight:bold'>{{ L1hs2 }}</span><span style='color:#3db161;font-weight:bold'>{{ L2hs2 }}</span><span style='color:#001EFF;font-weight:bold;'>{{L3hs2}}</span><span style='color:#f0882f;font-weight:'>{{L4hs2}}</span></div></div></div>
    <div class="row lines subtitle"><div class="col col-sm-4 hn"><div tappable (click)="hsignf(3)" [innerHTML]='hn3'></div></div><div class="col col-sm-8"><div><span style='color:#FF0000;font-weight:bold'>{{ L1hs3 }}</span><span style='color:#3db161;font-weight:bold'>{{ L2hs3 }}</span><span style='color:#001EFF;font-weight:bold;'>{{L3hs3}}</span><span style='color:#f0882f;font-weight:'>{{L4hs3}}</span></div></div></div>
    <div class="row lines subtitle"><div class="col col-sm-4 hn"><div tappable (click)="hsignf(4)" [innerHTML]='hn4'></div></div><div class="col col-sm-8"><div><span style='color:#FF0000;font-weight:bold'>{{ L1hs4 }}</span><span style='color:#3db161;font-weight:bold'>{{ L2hs4 }}</span><span style='color:#001EFF;font-weight:bold;'>{{L3hs4}}</span><span style='color:#f0882f;font-weight:'>{{L4hs4}}</span></div></div></div>
    <div class="row lines subtitle"><div class="col col-sm-4 hn"><div tappable (click)="hsignf(5)" [innerHTML]='hn5'></div></div><div class="col col-sm-8"><div><span style='color:#FF0000;font-weight:bold'>{{ L1hs5 }}</span><span style='color:#3db161;font-weight:bold'>{{ L2hs5 }}</span><span style='color:#001EFF;font-weight:bold;'>{{L3hs5}}</span><span style='color:#f0882f;font-weight:'>{{L4hs5}}</span></div></div></div>
    <div class="row lines subtitle"><div class="col col-sm-4 hn"><div tappable (click)="hsignf(6)" [innerHTML]='hn6'></div></div><div class="col col-sm-8"><div><span style='color:#FF0000;font-weight:bold'>{{ L1hs6 }}</span><span style='color:#3db161;font-weight:bold'>{{ L2hs6 }}</span><span style='color:#001EFF;font-weight:bold;'>{{L3hs6}}</span><span style='color:#f0882f;font-weight:'>{{L4hs6}}</span></div></div></div>
    <div class="row lines subtitle"><div class="col col-sm-4 hn"><div tappable (click)="hsignf(7)" [innerHTML]='hn7'></div></div><div class="col col-sm-8"><div><span style='color:#FF0000;font-weight:bold'>{{ L1hs7 }}</span><span style='color:#3db161;font-weight:bold'>{{ L2hs7 }}</span><span style='color:#001EFF;font-weight:bold;'>{{L3hs7}}</span><span style='color:#f0882f;font-weight:'>{{L4hs7}}</span></div></div></div>
    <div class="row lines subtitle"><div class="col col-sm-4 hn"><div tappable (click)="hsignf(8)" [innerHTML]='hn8'></div></div><div class="col col-sm-8"><div><span style='color:#FF0000;font-weight:bold'>{{ L1hs8 }}</span><span style='color:#3db161;font-weight:bold'>{{ L2hs8 }}</span><span style='color:#001EFF;font-weight:bold;'>{{L3hs8}}</span><span style='color:#f0882f;font-weight:'>{{L4hs8}}</span></div></div></div>
    <div class="row lines subtitle"><div class="col col-sm-4 hn"><div tappable (click)="hsignf(9)" [innerHTML]='hn9'></div></div><div class="col col-sm-8"><div><span style='color:#FF0000;font-weight:bold'>{{ L1hs9 }}</span><span style='color:#3db161;font-weight:bold'>{{ L2hs9 }}</span><span style='color:#001EFF;font-weight:bold;'>{{L3hs9}}</span><span style='color:#f0882f;font-weight:'>{{L4hs9}}</span></div></div></div>
    <div class="row lines subtitle"><div class="col col-sm-4 hn"><div tappable (click)="hsignf(10)" [innerHTML]='hn10'></div></div><div class="col col-sm-8"><div><span style='color:#FF0000;font-weight:bold'>{{ L1hs10 }}</span><span style='color:#3db161;font-weight:bold'>{{ L2hs10 }}</span><span style='color:#001EFF;font-weight:bold;'>{{L3hs10}}</span><span style='color:#f0882f;font-weight:'>{{L4hs10}}</span></div></div></div>
    <div class="row lines subtitle"><div class="col col-sm-4 hn"><div tappable (click)="hsignf(11)" [innerHTML]='hn11'></div></div><div class="col col-sm-8"><div><span style='color:#FF0000;font-weight:bold'>{{ L1hs11 }}</span><span style='color:#3db161;font-weight:bold'>{{ L2hs11 }}</span><span style='color:#001EFF;font-weight:bold;'>{{L3hs11}}</span><span style='color:#f0882f;font-weight:'>{{L4hs11}}</span></div></div></div>
    <div class="row lines subtitle"><div class="col col-sm-4 hn"><div tappable (click)="hsignf(12)" [innerHTML]='hn12'></div></div><div class="col col-sm-8"><div><span style='color:#FF0000;font-weight:bold'>{{ L1hs12 }}</span><span style='color:#3db161;font-weight:bold'>{{ L2hs12 }}</span><span style='color:#001EFF;font-weight:bold;'>{{L3hs12}}</span><span style='color:#f0882f;font-weight:'>{{L4hs12}}</span></div></div></div>
    <div class="card">
      <div class="card-body">
        <span><strong>LEVEL I Signinificators are highlighted in <span style="color:#f44141;font-weight:bold">RED</span><br />LEVEL II Signinificators are highlighted in <span style="color:#3db161;font-weight:bold">GREEN</span><br />LEVEL III Signinificators are highlighted in <span style="color:#001EFF;font-weight:bold">BLUE</span><br />LEVEL IV Signinificators are highlighted in <span style="color:#f0882f;font-weight:bold">ORANGE</span><br />For each Planet and House there are 4 levels of Significators where LEVEL I Significators are more predominant than LEVEL II, LEVEL II Significators are more predominant than LEVEL III and where as LEVEL III Significators are more predominant than LEVEL IV. </strong></span>
      </div>
    </div>
    <div *ngIf="bvm">
      <div *ngFor="let item of items">
        <div class="row lines" *ngIf="item.show" tappable (click)="itemTapped($event, item)">
          <div class="col col-sm-12">
            <p>
              <span class="ftitle">{{item.title | translate}}</span><br>
              <span class="fsub"> {{item.note | translate}}</span> <br>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
