<header>

  <nav class="vhonav navbar navbar-expand navbar-toggleable navbar-light bg-white border-bottom box-shadow">
    <button class="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target=".navbar-collapse"
            aria-label="Toggle navigation"
            [attr.aria-expanded]="isExpanded"
            (click)="toggle()">
      <span class="navbar-toggler-icon"></span>
    </button>
    <a class="navbar navbar-brand" (click)="vhoevt($event, 'home')" [routerLink]="['/']">
      <div class="navbar-nav ml-auto action-buttons">
      </div>
      <svg xmlns="http://www.w3.org/2000/svg" width="194" height="40" version="1.1">
        <defs>
          <style type="text/css">
            @import url('https://fonts.googleapis.com/css?family=Roboto:400,100,100italic,300,300italic,400italic,500,500italic,700,700italic,900,900italic');

            .small {
              font: bold 0.8rem roboto;
              fill: green
            }

            .heavy {
              font: bold 30px roboto;
              fill: #d35400
            }
          </style>
        </defs>
        <text x="0" y="22" class="heavy">VEDIC HOROO</text>
        <text x="65" y="36" class="small">
          DeCODE Your Destiny..<animate attributeType="CSS" attributeName="opacity"
                                        from="1" to="0" dur="5s" repeatCount="indefinite" />
        </text>
      </svg>

    </a>

    <h6 class="font-weight-bold ps-15">LAGNA: {{lagna}} <span class="text-muted text-uppercase">{{lagml}}-{{lagal}}-{{lagsl}}</span></h6>
    <div *ngIf="!user" class="nav-item">
      <button type="button" class="btn btn-link" (click)="vhoevt($event, 'login')">Login</button>
    </div>
    <div *ngIf="user" class="dropdown">
      <a href="#" id="navbarDropdownMenuLink" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
        <app-user-avatar [name]="user.name" [imageUrl]="user.imageUrl"></app-user-avatar>
      </a>
      <div class="dropdown-menu dropdown-menu-right mr-50" aria-labelledby="navbarDropdownMenuLink">
        <div class="dropdown-item"><strong>Balance:</strong> {{user.balance}} {{user.ccy}}<button class="btn btn-success" (click)="vhoevt($event, 'recharge')">Recharge Now</button></div>
        <a class="dropdown-item" href="#">Call History</a>
        <a class="dropdown-item" href="#">Customer Support</a>
        <div class="dropdown-divider"></div>
        <a class="dropdown-item" href="javascript:void(0)" (click)="vhoevt($event, 'logout')">Sign Out</a>
      </div>
    </div>
  </nav>
  <div id="menu1" class="justify-content-center">
    <nav class="navbar navbar-default mb-1">
      <div class="nav-item border border-secondary font-weight-bold">
        <a class="nav-link" [routerLink]="['/personal-details']" [queryParams]="{title:'Birth Chart'}" (click)="hidePanch()">Birth Chart</a>
      </div>
      <div class="nav-item border border-secondary font-weight-bold">
        <a class="nav-link" [routerLink]="['/personal-details']" [queryParams]="{title:'KP Astrology'}" (click)="hidePanch()">KP Astrology</a>
      </div>
      <div class="nav-item border border-secondary font-weight-bold">
        <a class="nav-link" [routerLink]="['/personal-details']" [queryParams]="{title:'Transit Predictions'}" (click)="hidePanch()">Monthly Predictions</a>
      </div>
      <div class="nav-item border border-secondary font-weight-bold">
        <a class="nav-link" [routerLink]="['/personal-details']" [queryParams]="{title:'Career Horoscope'}" (click)="hidePanch()">Career Horoscope</a>
      </div>
      <div class="nav-item border border-secondary font-weight-bold">
        <a class="nav-link" [routerLink]="['/personal-details']" [queryParams]="{title:'Money Horoscope'}" (click)="hidePanch()">Wealth Horoscope</a>
      </div>
      <div class="nav-item border border-secondary font-weight-bold">
        <a class="nav-link" [routerLink]="['/personal-details']" [queryParams]="{title:'Divisional Charts'}" (click)="hidePanch()">Varga Charts</a>
      </div>
      <div class="nav-item border border-secondary font-weight-bold">
        <a class="nav-link" [routerLink]="['/love-compatibility']" (click)="hidePanch()">Marriage Compatibility</a>
      </div>
      <div class="nav-item border border-secondary font-weight-bold">
        <a class="nav-link" [routerLink]="['/personal-details']" [queryParams]="{title:'Report'}" (click)="hidePanch()">Reports</a>
      </div>
      <div *ngIf="showSU" class="nav-item border border-secondary font-weight-bold">
        <a class="nav-link" [routerLink]="['/home']">Know Your Favourite Days</a>
      </div>
      <div *ngIf="showSU" class="nav-item border border-secondary font-weight-bold">
        <a class="nav-link" [routerLink]="['/home']">Prashna Jyotish</a>
      </div>
    </nav>
  </div>
  <div *ngIf="showPanch" id="panch" class="panch">
    <div class="container">
      <div class="row pt-2 text-center">
        <div class="col-sm-2"></div>
      <div class="col-sm-8  align-items-center justify-content-center">
        <div>
          <label for="cdt"><strong>Panchang for:</strong></label><span class="px-2 text-muted"><strong>{{cdt | date:'dd/MM/yyyy hh:mm a' }},{{place}}</strong></span>
        </div>
      </div>
        <div class="col-sm-2"></div>
        </div>
      <div class="row py-3">
        <div class="col-6 col-md-3 d-flex align-items-center justify-content-center">
          <div class="d-flex flex-column align-items-center">
            <svg class="feather feather-sunrise" fill="none" height="32" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24" width="32" xmlns="http://www.w3.org/2000/svg"><path d="M17 18a5 5 0 0 0-10 0" /><line x1="12" x2="12" y1="2" y2="9" /><line x1="4.22" x2="5.64" y1="10.22" y2="11.64" /><line x1="1" x2="3" y1="18" y2="18" /><line x1="21" x2="23" y1="18" y2="18" /><line x1="18.36" x2="19.78" y1="11.64" y2="10.22" /><line x1="23" x2="1" y1="22" y2="22" /><polyline points="8 6 12 2 16 6" /></svg>
            <span class="fw-bold">Sunrise:<span class="text-muted"> {{srise}}</span></span>
          </div>
        </div>
        <div class="col-6 col-md-3 d-flex align-items-center justify-content-center">
          <div class="d-flex flex-column align-items-center">
            <svg class="feather feather-sunset" fill="none" height="32" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24" width="32" xmlns="http://www.w3.org/2000/svg"><path d="M17 18a5 5 0 0 0-10 0" /><line x1="12" x2="12" y1="9" y2="2" /><line x1="4.22" x2="5.64" y1="10.22" y2="11.64" /><line x1="1" x2="3" y1="18" y2="18" /><line x1="21" x2="23" y1="18" y2="18" /><line x1="18.36" x2="19.78" y1="11.64" y2="10.22" /><line x1="23" x2="1" y1="22" y2="22" /><polyline points="16 5 12 9 8 5" /></svg>
            <span class="fw-bold">Sunset:<span class="text-muted"> {{sset}}</span></span>
          </div>
        </div>
        <div class="col-6 col-md-3 d-flex align-items-center justify-content-center">
          <div class="d-flex flex-column align-items-center">
            <i class="fa fa-solid fa-moon fs-3 mb-2"></i>
            <span class="fw-bold">Tithi:<span class="text-muted"> {{tithi}}</span></span>
          </div>
        </div>
        <div class="col-6 col-md-3 d-flex align-items-center justify-content-center">
          <div class="d-flex flex-column align-items-center">
            <i class="fa fa-solid fa-star fs-3 mb-2"></i>
            <span class="fw-bold">Nakshatra:<span class="text-muted" style="text-transform: uppercase;"> {{nak}}</span></span>
          </div>
        </div>
      </div>
      <div class="row py-3">
        <div class="col-6 col-md-3 d-flex align-items-center justify-content-center">
          <div class="d-flex flex-column align-items-center">
            <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
                 width="32" height="32" viewBox="0 0 256.000000 256.000000"
                 preserveAspectRatio="xMidYMid meet">

              <g transform="translate(0.000000,256.000000) scale(0.100000,-0.100000)"
                 fill="#000000" stroke="none">
                <path d="M1100 2545 c-251 -42 -501 -181 -659 -365 -77 -89 -110 -138 -160
-237 -161 -321 -161 -674 -1 -998 83 -169 210 -320 350 -417 24 -17 25 -18 5
-29 -11 -7 -39 -31 -62 -55 -167 -171 -17 -466 223 -441 135 14 233 125 234
264 0 114 -56 196 -173 252 -563 269 -761 957 -423 1476 135 206 346 358 591
425 120 33 328 38 456 12 396 -82 709 -393 790 -786 25 -121 25 -291 0 -412
-64 -310 -280 -581 -574 -719 -107 -50 -158 -123 -165 -236 -13 -208 204 -343
389 -243 154 84 184 287 61 413 -26 27 -54 51 -62 54 -12 4 -10 9 10 23 291
214 469 560 470 914 0 362 -189 720 -491 927 -225 155 -540 224 -809 178z
m-288 -2125 c45 -13 105 -77 113 -123 14 -75 -41 -165 -111 -183 -96 -24 -191
38 -202 131 -9 82 29 143 105 170 47 17 49 17 95 5z m1062 -18 c52 -30 71 -67
71 -135 0 -71 -25 -115 -81 -142 -162 -77 -310 128 -181 253 56 54 124 62 191
24z" />
              </g>
            </svg>
            <span class="fw-bold">Rahukal:<span class="text-muted"> {{rahukal}}</span></span>
          </div>
        </div>
        <div class="col-6 col-md-3 d-flex align-items-center justify-content-center">
          <div class="d-flex flex-column align-items-center">
            <i class="fa fa-solid fa-skull fs-3 mb-2"></i>
            <span class="fw-bold">Yamagand:<span class="text-muted"> {{yama}}</span></span>
          </div>
        </div>
        <div class="col-6 col-md-3 d-flex align-items-center justify-content-center">
          <div class="d-flex flex-column align-items-center">
            <i class="fa fa-regular fa-calendar-check fs-3 mb-2"></i>
            <span class="fw-bold">Karana:<span class="text-muted"> {{karn}}</span></span>
          </div>
        </div>
        <div class="col-6 col-md-3 d-flex align-items-center justify-content-center">
          <div class="d-flex flex-column align-items-center">
            <svg class="bi bi-moon-stars-fill" fill="currentColor" height="32" viewBox="0 0 16 16" width="32" xmlns="http://www.w3.org/2000/svg"><path d="M6 .278a.768.768 0 0 1 .08.858 7.208 7.208 0 0 0-.878 3.46c0 4.021 3.278 7.277 7.318 7.277.527 0 1.04-.055 1.533-.16a.787.787 0 0 1 .81.316.733.733 0 0 1-.031.893A8.349 8.349 0 0 1 8.344 16C3.734 16 0 12.286 0 7.71 0 4.266 2.114 1.312 5.124.06A.752.752 0 0 1 6 .278z" /><path d="M10.794 3.148a.217.217 0 0 1 .412 0l.387 1.162c.173.518.579.924 1.097 1.097l1.162.387a.217.217 0 0 1 0 .412l-1.162.387a1.734 1.734 0 0 0-1.097 1.097l-.387 1.162a.217.217 0 0 1-.412 0l-.387-1.162A1.734 1.734 0 0 0 9.31 6.593l-1.162-.387a.217.217 0 0 1 0-.412l1.162-.387a1.734 1.734 0 0 0 1.097-1.097l.387-1.162zM13.863.099a.145.145 0 0 1 .274 0l.258.774c.115.346.386.617.732.732l.774.258a.145.145 0 0 1 0 .274l-.774.258a1.156 1.156 0 0 0-.732.732l-.258.774a.145.145 0 0 1-.274 0l-.258-.774a1.156 1.156 0 0 0-.732-.732l-.774-.258a.145.145 0 0 1 0-.274l.774-.258c.346-.115.617-.386.732-.732L13.863.1z" /></svg>
            <span class="fw-bold">Yoga:<span class="text-muted">{{yog}}</span></span>
          </div>
        </div>
      </div>
    </div>
  </div>
</header>

