<body>
  <app-nav-menu>
  </app-nav-menu>
  <div id="call-msg" class="fixed-message" style="display:none;"></div>
  <div class="container">
    <router-outlet></router-outlet>
  </div>
  <app-astro-call (callEndedEvent)="onCallEnded($event)" [callEnded]="callEnded" *ngIf="showAstroCall"></app-astro-call>
  <app-home *ngIf='showHomePage'></app-home>
  <div class="contact" id="support">
    <div class="container">
      <div class="row">
        <div class="col-md-6">
          <div class="contact-left">
            <h3>CONTACT US</h3>
            <p class="style">For any queries please email us, we will attend you shortly..</p>
            <div class="get-intouch-left-address">
              <p>Block 10, Hill Ridge Springs</p>
              <p>Gachibowli, Hyderabad, India</p>
              <p class="style1">Email: <a href="mailto:info@vedichoroo.com"> info@vedichoroo.com</a></p>
              <p class="style2">Phone: +91-9010264567</p>
            </div>
            <div>
              <a href="https://play.google.com/store/apps/details?id=com.mypubz.eportal&pli=1">
                <img src="assets/images/get_it_on_play_logo_large.png" alt="Download VEDICHOROO Mobile App" />
              </a>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="contact-right">
            <div class="form">
              <form>
                <input type="text" id="cust-name" class="textbox" value=" Name" onfocus="this.value = '';" onblur="if (this.value == '') {this.value = 'Name';}">
                <input type="text" id="cust-email" class="textbox" value="Email" onfocus="this.value = '';" onblur="if (this.value == '') {this.value = 'Email';}">
                <div class="clearfix"></div>
                <div>
                  <textarea value="Message:" id="message" onfocus="this.value = '';" onblur="if (this.value == '') {this.value = ' Message';}">Message</textarea>
                </div>
                <div class="span4">
                  <input type="button" class="btn btn-success" id="contact-us" disabled="disabled" value="CONTACT US">
                  <div class="clearfix"></div>
                </div>
                <div class="span8">
                  <p id="pInfo" style="font-weight:bold;color:white"></p>
                </div>

              </form>
            </div>
          </div>
        </div>
        <div class="clearfix"></div>
      </div>

    </div>
  </div>
  <div class="footer">
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <div class="copy-right">
            <p>©Copyright 2020-21 All Rights Reserved  <a [routerLink]="['/home']">vedichoroo.com</a> <a [routerLink]="['/termsandconditions']"> Terms &amp; Conditions</a> <a [routerLink]="['/privacy-policy']"> Privacy Policy</a> <a [routerLink]="['/refundpolicy']"> Refund Policy</a></p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <ng-template #actvMdl let-modal>
    <div class="modal-header">
      <strong>ENTER ACTIVATION CODE</strong>
    </div>
    <div class="login modal-body">
      <div class="box">
        <div class="content">
          <div *ngIf="showLogin" class="form loginBox">
            <form method="" action="" accept-charset="UTF-8">
              <input id="actc" class="form-control" type="text" placeholder="Enter Activation Code" name="email">
              <div class="info"> <p [innerHTML]='msg3'></p></div>
              <input class="btn btn-default btn-login" type="button" value="SUBMIT" (click)="actvusr($event)">
            </form>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <div class="forgot register-footer">
        <span>Activation Code is sent to your registered mail id. Please contact our <a href="mailto:info@vedichoroo.com">Support</a> further any help!</span>
      </div>
    </div>
  </ng-template>
  <app-login (loginMdlLoaded)="onLoginMdlLoaded($event)" (onSubmitEvent)="modalService.dismissAll()"></app-login>
 <app-dob (dobMdlLoaded)="onDobMdlLoaded($event)" [userEvent]="userSubject.asObservable()" (onSubmitEvent)="modalService.dismissAll()"></app-dob>
 <app-recharge (recMdlLoaded)="onRecMdlLoaded($event)" (paymentSubmitted)="handlePaymentSubmitted($event)"></app-recharge>
 <ng-template #orderMdl let-modal>
 <div class="modal-header">
  <span class="modal-title">Order Processing</span>
  </div>
  <div class="modal-body">
  <div class="content">
  <div class="text-center"><strong>{{ordermsg}}</strong></div>
  </div>
  </div>
 </ng-template>
</body>
