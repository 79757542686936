<h1>Returns and Refunds Policy</h1>


<p class="text-info">Thank you for shopping at VEDICHOROO.</p>

<p class="text-info">Please read this policy carefully.</p>




<h2>Digital products</h2>

<p class="text-info">We issue refunds for digital products within 7 days of the original purchase of the product.</p>

<p class="text-info">We recommend contacting us for assistance if you experience any issues receiving or downloading our products.</p>



<h2>Contact us</h2>

<p class="text-info">If you have any questions about our Returns and Refunds Policy, please contact  please contact <a href="mailto:info@vedichoroo.com">info@vedichoroo.com</a></p>

<ul>
<li>
    <p class="text-info">By phone number: +91-9010263567</p>
</li>
<li>
    <p class="text-info">By email: <a href="mailto:info@vedichoroo.com">info@vedichoroo.com</a></p>
</li>
</ul>
