<div class="container-fluid">
  <div *ngIf="showList" class="row justify-content-center">
    <div class="col-sm-6 mb-4 border-2 border-solid">
      <h4 class="text-center">Love Compatibility Report</h4>
      <div class="form-group row mb-2">
        <label class="col-sm-2 form-check-label" for="nam1">His Name</label>
        <div class="col-sm-10">
          <div class="input-group">
            <input class="form-control" type="text" name="nam1" [(ngModel)]="nam1" [ngModelOptions]="{standalone: true}" />
          </div>
        </div>
      </div>
      <div class="form-group row">
        <label class="col-sm-2 form-check-label" for="nam">His Date of Birth</label>
        <div class="col-sm-2">
          <div class="input-group">
            <input class="form-control" type="number" name="day1" [(ngModel)]="day1" [ngModelOptions]="{standalone: true}" placeholder="DD" min="1" max="31" />
          </div>
        </div>
        <div class="col-sm-2">
          <div class="input-group">
            <input class="form-control" type="number" name="mon1" [(ngModel)]="mon1" [ngModelOptions]="{standalone: true}" placeholder="MM" min="1" max="12" />
          </div>
        </div>
        <div class="col-sm-2">
          <div class="input-group">
            <input class="form-control" type="number" name="year1" [(ngModel)]="year1" [ngModelOptions]="{standalone: true}" placeholder="YYYY" min="1900" max="2999" />
          </div>
        </div>
      </div>
      <div class="form-group row">
        <label class="col-sm-2 form-check-label" for="nam">His Time of Birth</label>
        <div class="col-sm-2">
          <div class="input-group">
            <input class="form-control" type="number" name="hou1" [(ngModel)]="hou1" [ngModelOptions]="{standalone: true}" placeholder="HH" />
          </div>
        </div>
        <div class="col-sm-2">
          <div class="input-group">
            <input class="form-control" type="number" name="min1" [(ngModel)]="min1" [ngModelOptions]="{standalone: true}" placeholder="MM" />
          </div>
        </div>
        <div class="col-sm-2">
          <div class="input-group">
            <input class="form-control" type="number" name="sec1" [(ngModel)]="sec1" [ngModelOptions]="{standalone: true}" placeholder="SS" />
          </div>
        </div>
      </div>
      <div class="form-group row">
        <label class="col-sm-2 form-check-label" for="place">His Place of Birth</label>
        <div class="col-sm-10">
          <div class="input-group">
            <input class="col-sm-10 form-control" type="text" name="place1" [(ngModel)]="autocomplete.query1" (input)="AddressChange($event, 1)" placeholder="Min 3 chars" />
          </div>
        </div><span class="places col-sm-12 border-light" *ngFor="let item of autocompleteItems1" tappable (click)="chooseItem(item, 1)">
          {{ item }}
        </span>
      </div>
      <div class="form-group row mb-2">
        <label class="col-sm-2 form-check-label" for="nam">Her Name</label>
        <div class="col-sm-10">
          <div class="input-group">
            <input class="form-control" type="text" name="nam2" [(ngModel)]="nam2" [ngModelOptions]="{standalone: true}" />
          </div>
        </div>
      </div>
      <div class="form-group row">
        <label class="col-sm-2 form-check-label" for="nam">Her Date of Birth</label>
        <div class="col-sm-2">
          <div class="input-group">
            <input class="form-control" type="number" name="day2" [(ngModel)]="day2" [ngModelOptions]="{standalone: true}" placeholder="DD" min="1" max="31" />
          </div>
        </div>
        <div class="col-sm-2">
          <div class="input-group">
            <input class="form-control" type="number" name="mon2" [(ngModel)]="mon2" [ngModelOptions]="{standalone: true}" placeholder="MM" min="1" max="12" />
          </div>
        </div>
        <div class="col-sm-2">
          <div class="input-group">
            <input class="form-control" type="number" name="year2" [(ngModel)]="year2" [ngModelOptions]="{standalone: true}" placeholder="YYYY" min="1900" max="2999" />
          </div>
        </div>
      </div>
      <div class="form-group row">
        <label class="col-sm-2 form-label" for="nam">Her Time of Birth</label>
        <div class="col-sm-2">
          <div class="input-group">
            <input class="form-control" type="number" name="hou2" [(ngModel)]="hou2" [ngModelOptions]="{standalone: true}" placeholder="HH" />
          </div>
        </div>
        <div class="col-sm-2">
          <div class="input-group">
            <input class="form-control" type="number" name="min2" [(ngModel)]="min2" [ngModelOptions]="{standalone: true}" placeholder="MM" />
          </div>
        </div>
        <div class="col-sm-2">
          <div class="input-group">
            <input class="form-control" type="number" name="sec2" [(ngModel)]="sec2" [ngModelOptions]="{standalone: true}" placeholder="SS" />
          </div>
        </div>
      </div>
      <div class="form-group row">
        <label class="col-sm-2 form-label" for="place">Her Place of Birth</label>
        <div class="col-sm-10">
          <div class="input-group">
            <input class="form-control" type="text" name="place2" [(ngModel)]="autocomplete.query2" (input)="AddressChange($event, 2)" placeholder="Min 3 chars" />
          </div>
        </div><span class="places col-sm-12 border-light" *ngFor="let item of autocompleteItems2" tappable (click)="chooseItem(item, 2)">
          {{ item }}
        </span>
      </div>
      <div class="row info"> <p class="text-info" [innerHTML]='info'></p></div>
      <div class="form-group row">
        <button type="button" class="btn btn-block btn-primary w-100" (click)="save($event)">SUBMIT</button>
        <div *ngIf="wait" class="spinner-border" role="status">
          <span class="text-info sr-only">Please wait...</span>
        </div>
      </div>
    </div>
  </div>
<h2 *ngIf="showGrid">Compatibility Report</h2>
<div class="row"><div class="col col-sm-12" *ngIf="showGrid" [innerHTML]='frep'></div></div><br>
<div class="row justify-content-cente"><div class="col col-sm-6"><canvas #cnvCC></canvas></div></div>
<div *ngIf="showGrid">
  <div class="row lines maintitle"><div class="col col-sm-6">{{nam1}}</div><div class="col col-sm-6">{{nam2}}</div></div>
  <div class="row lines"><div class="col col-sm-6"><div [innerHTML]='hisdb'></div></div><div class="col col-sm-6"><div [innerHTML]='herdb'></div></div></div>
  <div class="row maintitle"><div class="col col-sm-12">Moon Sign</div></div>
  <div class="row lines"><div class="col col-sm-6"><div [innerHTML]='msign'></div></div><div class="col col-sm-6"><div [innerHTML]='wsign'></div></div></div>
  <div class="row maintitle"><div class="col col-sm-12">Birth Star</div></div>
  <div class="row lines"><div class="col col-sm-6"><div [innerHTML]='mstar'></div></div><div class="col col-sm-6"><div [innerHTML]='wstar'></div></div></div>
  <div class="row maintitle"><div class="col col-sm-12">Ruling Planet</div></div>
  <div class="row lines"><div class="col col-sm-6"><div [innerHTML]='mpl'></div></div><div class="col col-sm-6"><div [innerHTML]='wpl'></div></div></div>
  <div class="row maintitle"><div class="col col-sm-6">Ruling Animal</div></div>
  <div class="row lines"><div class="col col-sm-6"><div [innerHTML]='maml'></div></div><div class="col col-sm-6"><div [innerHTML]='waml'></div></div></div>
  <div class="row maintitle"><div class="col col-sm-12">Characteristics</div></div>
  <div class="row lines"><div class="col col-sm-6"><div [innerHTML]='mchr'></div></div><div class="col col-sm-6"><div [innerHTML]='wchr'></div></div></div>
  <div class="row maintitle"><div class="col col-sm-10"> Asta Kootna</div><div class="col col-sm-2"> Points</div></div>
  <div class="row lines" *ngFor="let key of objectKeys(oKota)"><div class="lbl col col-sm-10"><div tappable (click)="showInf('Asta Koota')">{{key}}</div></div><div class="col col-sm-2"> <div [innerHTML]='oKota[key].pts'></div></div></div>
</div>
<div class="report" *ngIf="showGrid">
    <div class="row maintitle"><div class="col col-sm-12">{{'DOSHAS'|translate}}</div></div>
		 <div class=" row bd"><div class="lbl col col-md-6" size="4"><div class="bstar" tappable (click)="dosdtl('strii')">{{'Strii Dheergha'|translate}}</div></div><div class="col col-md-6">{{dos.strii}}</div></div>   
		 <div class=" row bd"><div class="lbl col col-md-6" size="4"><div class="bstar" tappable (click)="dosdtl('vedh')">{{'Vedh Dosha'|translate}}</div></div><div class="col col-md-6">{{dos.vedh}}</div></div>   
		 <div class=" row bd"><div class="lbl col col-md-6" size="4"><div class="bstar" tappable (click)="dosdtl('raju')">{{'Rajju Dosha'|translate}}</div></div><div class="col col-md-6">{{dos.raju}}</div></div>   
		 <div class=" row bd"><div class="lbl col col-md-6" size="4"><div class="bstar" tappable (click)="dosdtl('attr')">{{'Vasya'|translate}}</div></div><div class="col col-md-6">{{dos.attr}}</div></div>   
		 <div class=" row bd"><div class="lbl col col-md-6" size="4"><div class="bstar" tappable (click)="dosdtl('pap')">{{'Papasamyam'|translate}}</div></div><div class="col col-md-6">{{dos.pap}}</div></div>   
		 <div class=" row bd"><div class="lbl col col-md-6" size="4"><div class="bstar" tappable (click)="dosdtl('kal')">{{'Kaltra Dosha'|translate}}</div></div><div class="col col-md-6">{{dos.kal}}</div></div>   
		 <div class=" row bd"><div class="lbl col col-md-6" size="4"><div class="bstar" tappable (click)="dosdtl('strii')">{{'Mangal Dosha'|translate}}</div></div><div class="col col-md-6">{{dos.mngl}}</div></div>   
</div><br><br>
</div>
