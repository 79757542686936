<div class="container-fluid">
  <p class='msg' [innerHTML]='info'></p>
  <div class="row">
    <div id="bchart" class="col col-sm-6 m-1"><div #birthChart></div></div>
    <div class="col col-sm-5">
      <div id="nchart" class="col col-sm-6 m-1"><div #navChart></div></div>
    </div>
  </div>
  <div *ngIf="showBD">
    <div class="row maintitle"><div class="col col-sm-12">{{'Birth Details'|translate}}</div></div>
    <div class="row bd"><div class="lbl col col-sm-6"><div>{{'Date of Birth'|translate}}</div></div><div class="col col-sm-6">{{dob}}</div></div>
    <div class="row bd"><div class="lbl col col-sm-6"><div>{{'Lagna'|translate}}</div></div><div class="col col-sm-6">{{lagna}}</div></div>
    <div class="row bd"><div class="lbl col col-sm-6"><div>{{'Lagna Lord'|translate}}</div></div><div class="col col-sm-6">{{lagna_lord}}</div></div>
    <div class="row bd"><div class="lbl col col-sm-6"><div>{{'Moon Sign'|translate}}</div></div><div class="col col-sm-6">{{moon_sign_f}}</div></div>
    <div class="row bd"><div class="lbl col col-sm-6"><div>{{'Sun Sign'|translate}}</div></div><div class="col col-sm-6">{{sun_sign}}</div></div>
    <div class="row bd"><div class="lbl col col-sm-6"><div>{{'Tithi'|translate}}</div></div><div class="col col-sm-6">{{tithi}}</div></div>
    <div class="row bd"><div class="lbl col col-sm-6"><div>{{'Birth Star'|translate}}</div></div><div class="col col-sm-6">{{birth_star}}</div></div>
    <div class="row bd"><div class="lbl col col-sm-6"><div>{{'Star Lord'|translate}}</div></div><div class="col col-sm-6">{{star_lord}}</div></div>
    <div class="row bd"><div class="lbl col col-sm-6"><div>{{'Moon Phase'|translate}}</div></div><div class="col col-sm-6">{{moon_phase}}</div></div>
    <div class="row lines title"><div class="col col-sm-2">PLANET</div><div class="col col-sm-2">POS</div><div class="col col-sm-3">RASI</div><div class="col col-sm-3">NAK</div><div class="col col-sm-2">NAK LORD</div></div>
    <div class="row lines subtitle"><div class="col col-sm-2" [innerHTML]='pnam1'></div><div class="col col-sm-2" [innerHTML]='ppos1'></div><div class="col col-sm-3" [innerHTML]='pras1'></div><div class="col col-sm-3" [innerHTML]='pnak1'></div><div class="col col-sm-2" [innerHTML]='nakl1'></div></div>
    <div class="row lines subtitle"><div class="col col-sm-2" [innerHTML]='pnam2'></div><div class="col col-sm-2" [innerHTML]='ppos2'></div><div class="col col-sm-3" [innerHTML]='pras2'></div><div class="col col-sm-3" [innerHTML]='pnak2'></div><div class="col col-sm-2" [innerHTML]='nakl2'></div></div>
    <div class="row lines subtitle"><div class="col col-sm-2" [innerHTML]='pnam3'></div><div class="col col-sm-2" [innerHTML]='ppos3'></div><div class="col col-sm-3" [innerHTML]='pras3'></div><div class="col col-sm-3" [innerHTML]='pnak3'></div><div class="col col-sm-2" [innerHTML]='nakl3'></div></div>
    <div class="row lines subtitle"><div class="col col-sm-2" [innerHTML]='pnam4'></div><div class="col col-sm-2" [innerHTML]='ppos4'></div><div class="col col-sm-3" [innerHTML]='pras4'></div><div class="col col-sm-3" [innerHTML]='pnak4'></div><div class="col col-sm-2" [innerHTML]='nakl4'></div></div>
    <div class="row lines subtitle"><div class="col col-sm-2" [innerHTML]='pnam5'></div><div class="col col-sm-2" [innerHTML]='ppos5'></div><div class="col col-sm-3" [innerHTML]='pras5'></div><div class="col col-sm-3" [innerHTML]='pnak5'></div><div class="col col-sm-2" [innerHTML]='nakl5'></div></div>
    <div class="row lines subtitle"><div class="col col-sm-2" [innerHTML]='pnam6'></div><div class="col col-sm-2" [innerHTML]='ppos6'></div><div class="col col-sm-3" [innerHTML]='pras6'></div><div class="col col-sm-3" [innerHTML]='pnak6'></div><div class="col col-sm-2" [innerHTML]='nakl6'></div></div>
    <div class="row lines subtitle"><div class="col col-sm-2" [innerHTML]='pnam7'></div><div class="col col-sm-2" [innerHTML]='ppos7'></div><div class="col col-sm-3" [innerHTML]='pras7'></div><div class="col col-sm-3" [innerHTML]='pnak7'></div><div class="col col-sm-2" [innerHTML]='nakl7'></div></div>
    <div class="row lines subtitle"><div class="col col-sm-2" [innerHTML]='pnam8'></div><div class="col col-sm-2" [innerHTML]='ppos8'></div><div class="col col-sm-3" [innerHTML]='pras8'></div><div class="col col-sm-3" [innerHTML]='pnak8'></div><div class="col col-sm-2" [innerHTML]='nakl8'></div></div>
    <div class="row lines subtitle"><div class="col col-sm-2" [innerHTML]='pnam9'></div><div class="col col-sm-2" [innerHTML]='ppos9'></div><div class="col col-sm-3" [innerHTML]='pras9'></div><div class="col col-sm-3" [innerHTML]='pnak9'></div><div class="col col-sm-2" [innerHTML]='nakl9'></div></div>
    <div class="row maintitle"><div class="col col-sm-12">{{'Detailed Analysis'|translate}}</div></div>
    <div *ngFor="let key of objectKeys(oYog)">
      <div class="row title"><div class="col col-sm-12"><div [innerHTML]='oYog[key].desc'></div></div></div>
    </div>
    <div class="row maintitle"><div class="col col-sm-12">{{'Hora Chart Analysis'|translate}}</div></div>
    <div class="row title"><div class="col col-sm-12"><div [innerHTML]='akashWani'></div></div></div>
  </div>
</div>
