  <ng-template #dobMdl>
  <div class="app-dob modal-header">
  <div class="app-dob avatar" *ngIf="user.imageUrl" [ngStyle]="{'background-image': 'url(' + user.imageUrl + ')'}"></div>
  <span class="modal-title">{{user.name}}</span>
  </div>
  <div class="modal-body">
    <div class="content">
	<h5>Please enter your Birth details</h5>
      <div class="form-group pt-4 pb-4 d-flex align-items-center">
	  <label class="col-sm-2 col-form-label label-small">Gender</label>
	  <div class="col col-sm-10">
    <div class="form-check form-check-inline">
        
            <input class="form-check-input" type="radio" [(ngModel)]="gen" name="flexRadioDefault" id="flexRadioDefault1" value="male">
        <label class="form-check-label label-small" for="flexRadioDefault1">
            Male
        </label>
    </div>
    <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio" [(ngModel)]="gen" name="flexRadioDefault" id="flexRadioDefault2" value="female">
        <label class="form-check-label label-small" for="flexRadioDefault2">
            Female
        </label>
    </div>	</div></div>
<div class="form-group row">
  <label class="col-sm-2 col-form-label label-small" for="dob">Date of Birth</label>
  <div class="col-sm-10"><div class="input-group">
    <input type="text" class="form-control" [(ngModel)]="dob" ngbDatepicker #d="ngbDatepicker" (click)="d.toggle()" [minDate]="{year: 0001, month:1, day: 1}" [maxDate]="{year: 3000, month:1, day: 1}">
   <div class="input-group-append">
        <button class="btn btn-outline-secondary fa fa-calendar" (click)="d.toggle()" type="button"></button>
      </div>
    </div>
  </div>
</div>
<div class="form-group row pb-4 d-flex align-items-center">
    <label class="col-sm-2 col-form-label label-small" for="timepicker">Time</label>
    <div class="col-sm-10">
      <div class="input-group">
	  <ngb-timepicker [(ngModel)]="tob" [seconds]="true"></ngb-timepicker>
      </div>
    </div>
</div>
      <div class="form-group row">
        <label class="col-sm-2 col-form-label label-small" for="place">Place of Birth</label>
        <div class="col-sm-10"><div class="input-group"><input class="form-control" type="text" name="place" autocomplete="off" [(ngModel)]="autocomplete.query" (input)="AddressChange($event)" placeholder="Min 3 chars"></div></div>
        <span class="places col-sm-12 border-light" *ngFor="let item of autocompleteItems" tappable (click)="chooseItem(item)">
          {{ item }}
        </span>
	  </div>
	  <div class="row">
			<button class="btn btn-lg btn-block btn-success" (click)="save($event)">
              SUBMIT
            </button>
	  </div>
  </div>
  </div>
  <div class="modal-footer">
  <small class="text-muted">Secured by VEDCICHOROO</small>
  </div>
  </ng-template>
